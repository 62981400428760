define(["require", "exports", "preact/jsx-runtime", "ojs/ojvcomponent", "preact/hooks", "react-router-dom", "@os/os-common/lib/ioc", "@os/os-common/lib/services/TranslationService", "@os/os-components/lib/OsHooks/useTranslationFor", "./footer", "./header", "./content/index", "../hooks/useLogin", "../hooks/useQuery", "./common/errorPage", "./common/spinner", "../utils/analytics", "../utils/chatbot", "../utils/environment", "../contexts/recentActivityProvider", "../contexts/userProvider", "ojs/ojcontext", "app.scss"], function (require, exports, jsx_runtime_1, ojvcomponent_1, hooks_1, react_router_dom_1, ioc_1, TranslationService_1, useTranslationFor_1, footer_1, header_1, index_1, useLogin_1, useQuery_1, errorPage_1, spinner_1, analytics_1, chatbot_1, environment_1, recentActivityProvider_1, userProvider_1, Context) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.App = void 0;
    const translationService = ioc_1.container.get(TranslationService_1.TranslationService);
    const AppComponent = ({ loading: loginLoading, error: loginError, userLogin, login, logout, environment, accessToken }) => {
        const appName = (0, useTranslationFor_1.useTranslationFor)("appName");
        const appVersion = (0, useTranslationFor_1.useTranslationFor)("appVersion");
        const { language, user } = (0, hooks_1.useContext)(userProvider_1.UserContext);
        const { append: addRecentActivity } = (0, hooks_1.useContext)(recentActivityProvider_1.RecentActivityContext);
        const [translationLoading, setTranslationLoading] = (0, hooks_1.useState)(true);
        const [menuOpened, setMenuOpened] = (0, hooks_1.useState)(false);
        const [recentActivity, setRecentActivity] = (0, hooks_1.useState)(undefined);
        const { loading: pluginsLoading, result: plugins, error: pluginsError } = (0, useQuery_1.usePluginsQuery)(accessToken);
        const { loading: navigationsLoading, result: navigations, error: navigationsError } = (0, useQuery_1.useNavigationsQuery)(accessToken);
        const { result: partnerNavigations } = (0, useQuery_1.usePartnerAssistanceQuery)(accessToken);
        const loading = loginLoading || translationLoading || pluginsLoading || navigationsLoading;
        const error = loginError || pluginsError || navigationsError;
        const { finalPlugins, finalNavigations } = (0, hooks_1.useMemo)(() => {
            let finalPlugins = plugins;
            if (environment_1.pluginsFromSearchParam && plugins) {
                const pluginsMap = plugins.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr.name]: curr })), {});
                const overridePluginsMap = environment_1.pluginsFromSearchParam.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr.name]: curr })), {});
                const newPluginsMap = Object.assign(Object.assign({}, pluginsMap), overridePluginsMap);
                finalPlugins = Object.keys(newPluginsMap).map(k => newPluginsMap[k]);
            }
            let finalNavigations = navigations;
            if (environment_1.navigationsFromSearchParam && navigations) {
                const navigationsMap = navigations.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr.labelKey]: curr })), {});
                const overrideNavigationsMap = environment_1.navigationsFromSearchParam.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr.labelKey]: curr })), {});
                const newNavigatiosMap = Object.assign(Object.assign({}, navigationsMap), overrideNavigationsMap);
                finalNavigations = Object.keys(newNavigatiosMap).map(k => newNavigatiosMap[k]);
            }
            return { finalPlugins, finalNavigations };
        }, [plugins, navigations]);
        const [navigate, setNavigate] = (0, hooks_1.useState)();
        const getNavigateRef = (navigate) => {
            setNavigate(_ => navigate);
        };
        const [showExitPopup, setShowExitPopup] = (0, hooks_1.useState)();
        const getShowExitPopupRef = (showExitPopup) => {
            setShowExitPopup(_ => showExitPopup);
        };
        const [isNavigationEnabled, setNavigationEnabled] = (0, hooks_1.useState)(true);
        const getPluginRef = (plugin) => {
            var _a, _b, _c, _d;
            setNavigationEnabled((_b = (_a = plugin.options) === null || _a === void 0 ? void 0 : _a.isNavigationEnabled) !== null && _b !== void 0 ? _b : true);
            (0, chatbot_1.showChatbot)((_d = (_c = plugin.options) === null || _c === void 0 ? void 0 : _c.isChatbotEnabled) !== null && _d !== void 0 ? _d : true);
        };
        (0, hooks_1.useEffect)(() => {
            (0, analytics_1.initAnalytics)(!window.location.href.includes("localhost"));
            Context.getPageContext().getBusyContext().applicationBootstrapComplete();
        }, []);
        (0, hooks_1.useEffect)(() => {
            document.title = `${appName}${appVersion !== "appVersion" ? " " + appVersion : ""}`;
        }, [appName, appVersion]);
        (0, hooks_1.useEffect)(() => {
            const element = document.body;
            if (menuOpened) {
                element === null || element === void 0 ? void 0 : element.classList.add("os-disable-horizontal-scroll");
            }
            else {
                element === null || element === void 0 ? void 0 : element.classList.remove("os-disable-horizontal-scroll");
            }
        }, [menuOpened]);
        const isMaintenance = (0, hooks_1.useMemo)(() => (environment === null || environment === void 0 ? void 0 : environment.maintenanceStart) !== undefined && (environment === null || environment === void 0 ? void 0 : environment.maintenanceEnd) !== undefined
            && new Date().valueOf() > new Date(environment.maintenanceStart).valueOf()
            && new Date().valueOf() < new Date(environment.maintenanceEnd).valueOf(), [environment === null || environment === void 0 ? void 0 : environment.maintenanceStart, environment === null || environment === void 0 ? void 0 : environment.maintenanceEnd]);
        (0, hooks_1.useEffect)(() => {
            if ((environment === null || environment === void 0 ? void 0 : environment.botChannelId) && (environment === null || environment === void 0 ? void 0 : environment.features.botClientAuthEnabled) !== undefined) {
                (0, chatbot_1.initChatbot)(environment.botChannelId, environment.features.botClientAuthEnabled);
            }
        }, [environment === null || environment === void 0 ? void 0 : environment.botChannelId, environment === null || environment === void 0 ? void 0 : environment.features.botClientAuthEnabled]);
        (0, hooks_1.useEffect)(() => {
            if (environment === null || environment === void 0 ? void 0 : environment.name) {
                translationService.init({
                    debug: environment_1.isDebugOn,
                    lng: "en",
                    ns: environment.name,
                    defaultNS: environment.name,
                    returnEmptyString: false,
                    load: "currentOnly",
                    backend: {
                        loadPath: `/languages/{{lng}}/{{ns}}.json?v=${process.env.VERSION}`,
                        allowMultiLoading: false
                    },
                    lowerCaseLng: true,
                    fallbackLng: "en"
                }, () => {
                    setTranslationLoading(false);
                });
            }
        }, [environment === null || environment === void 0 ? void 0 : environment.name]);
        (0, hooks_1.useEffect)(() => {
            if (!translationLoading) {
                translationService.setLanguage(language);
                (0, chatbot_1.changeChatbotLanguage)(language);
            }
        }, [language, translationLoading]);
        (0, hooks_1.useEffect)(() => {
            if (user) {
                (0, chatbot_1.updateChatbotConfig)(user);
            }
        }, [user]);
        const handleNavigation = (0, hooks_1.useCallback)((item) => {
            var _a;
            if ((0, useQuery_1.isPluginItem)(item)) {
                const plugin = finalPlugins.find(p => p.name === item.pluginName);
                if (plugin) {
                    const path = plugin.path + ((_a = item.appendPath) !== null && _a !== void 0 ? _a : "");
                    navigate(path);
                    addRecentActivity({ plugin: plugin.name, url: path, label: item.labelKey, creationDate: new Date().toISOString() });
                }
                return true;
            }
            else if (environment === null || environment === void 0 ? void 0 : environment.features.exitPopupEnabled) {
                if ((0, useQuery_1.isLinkItem)(item)) {
                    setRecentActivity({ plugin: "link", url: item.path, label: item.labelKey, creationDate: new Date().toISOString() });
                }
                showExitPopup(item.path, item.openInNewTab);
                return true;
            }
            else if ((0, useQuery_1.isLinkItem)(item)) {
                addRecentActivity({ plugin: "link", url: item.path, label: item.labelKey, creationDate: new Date().toISOString() });
                return false;
            }
            return false;
        }, [navigate, finalPlugins, accessToken, environment === null || environment === void 0 ? void 0 : environment.features.exitPopupEnabled]);
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(header_1.Header, { appName: appName, appVersion: appVersion !== "appVersion" ? appVersion : undefined, userLogin: (user === null || user === void 0 ? void 0 : user.displayName) || userLogin, login: login, logout: logout, navigate: handleNavigation, navigations: (isNavigationEnabled && finalNavigations) || undefined, partnerNavigations: partnerNavigations, helpLinkTemplate: environment === null || environment === void 0 ? void 0 : environment.helpLinkTemplate, myProfileLink: environment === null || environment === void 0 ? void 0 : environment.myProfileLink, contactLink: environment === null || environment === void 0 ? void 0 : environment.contactLink, accessToken: accessToken, onMenuOpenedChanged: setMenuOpened, environment: environment }), isMaintenance && (0, jsx_runtime_1.jsx)(errorPage_1.ErrorPage, { errorType: "maintenance", environment: environment }), (loading && !error && !isMaintenance) && (0, jsx_runtime_1.jsx)(spinner_1.Spinner, {}), (!loading && error && !isMaintenance) && (0, jsx_runtime_1.jsx)(errorPage_1.ErrorPage, { errorType: "fatal", environment: environment }), (!loading && !error && !isMaintenance) && (0, jsx_runtime_1.jsx)(react_router_dom_1.BrowserRouter, { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(finalPlugins !== null && finalPlugins !== void 0 ? finalPlugins : [undefined]).map(p => {
                                const pluginSplatRoute = p ? `${p.path}/*` : undefined;
                                return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: pluginSplatRoute !== null && pluginSplatRoute !== void 0 ? pluginSplatRoute : window.location.pathname, element: (0, jsx_runtime_1.jsx)(index_1.Content, { plugin: p, environment: environment, accessToken: accessToken, navigateRef: getNavigateRef, pluginRef: getPluginRef, menuOpened: menuOpened, showExitPopup: showExitPopup }) }));
                            }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "*", element: (0, jsx_runtime_1.jsx)(index_1.ErrorPageContent, { environment: environment, navigateRef: getNavigateRef, menuOpened: menuOpened, showExitPopup: showExitPopup }) })] }) }), (0, jsx_runtime_1.jsx)(ExitPopup, { recentActivity: recentActivity, resetRecentActivity: () => setRecentActivity(undefined), showExitPopupRef: getShowExitPopupRef }), (0, jsx_runtime_1.jsx)(footer_1.Footer, {})] }));
    };
    const ExitPopup = ({ recentActivity, resetRecentActivity, showExitPopupRef }) => {
        const { append: addRecentActivity } = (0, hooks_1.useContext)(recentActivityProvider_1.RecentActivityContext);
        const yesButton = (0, useTranslationFor_1.useTranslationFor)("exitPopup.button.yes");
        const noButton = (0, useTranslationFor_1.useTranslationFor)("exitPopup.button.no");
        const exitPopupTitle = (0, useTranslationFor_1.useTranslationFor)("exitPopup.title");
        const [exitPopupOpened, setExitPopupOpened] = (0, hooks_1.useState)(false);
        const [destination, setDestination] = (0, hooks_1.useState)({ url: "", openInNewTab: false });
        const showExitPopup = (url, openInNewTab) => {
            setDestination({ url, openInNewTab });
            setExitPopupOpened(true);
        };
        const proceedNavigation = (destination) => {
            setExitPopupOpened(false);
            setDestination({ url: "", openInNewTab: false });
            if (recentActivity) {
                addRecentActivity(recentActivity);
            }
            resetRecentActivity();
            if (destination.openInNewTab) {
                window.open(destination.url, "_blank");
                return;
            }
            window.location.href = destination.url;
        };
        const noButtonClicked = () => {
            setExitPopupOpened(false);
            setDestination({ url: "", openInNewTab: false });
            resetRecentActivity();
        };
        (0, hooks_1.useEffect)(() => {
            showExitPopupRef(showExitPopup);
        }, []);
        return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: exitPopupOpened && (0, jsx_runtime_1.jsxs)("os-dialog", { openDialog: true, width: 800, dialogTitle: exitPopupTitle, dialogPosition: "top", className: "os-header-language-dialog", cancelBehavior: "none", children: [(0, jsx_runtime_1.jsx)("os-bind-text", { text: "exitPopup.description" }), (0, jsx_runtime_1.jsxs)("div", { slot: "dialog-footer", className: "oj-flex oj-sm-justify-content-flex-end", children: [(0, jsx_runtime_1.jsx)("oj-button", { id: "yesBtn", class: "oj-sm-margin-2x-end", chroming: "callToAction", onojAction: () => { proceedNavigation(destination); }, children: yesButton }), (0, jsx_runtime_1.jsx)("oj-button", { id: "noBtn", onojAction: noButtonClicked, children: noButton })] })] }) }));
    };
    const AppContainer = () => {
        const { loading: environmentLoading, result: environment } = (0, useQuery_1.useEnvironmentQuery)();
        const { loading: loginLoading, error, userLogin, login, logout, accessToken, accessTokenExpired } = (0, useLogin_1.useLogin)(environment);
        const loading = environmentLoading || loginLoading;
        const trSessionExpiryTitle = (0, useTranslationFor_1.useTranslationFor)("error.session.title");
        const trSessionExpiryDescription = (0, useTranslationFor_1.useTranslationFor)("error.session.description");
        const trSignIn = (0, useTranslationFor_1.useTranslationFor)("userMenu.signIn");
        const trCancel = (0, useTranslationFor_1.useTranslationFor)("cancel");
        return ((0, jsx_runtime_1.jsx)(recentActivityProvider_1.RecentActivityProvider, { accessToken: accessToken, children: (0, jsx_runtime_1.jsx)(userProvider_1.UserProvider, { accessToken: accessToken, children: (0, jsx_runtime_1.jsxs)("div", { id: "appContainer", class: "oj-web-applayout-page", children: [(0, jsx_runtime_1.jsx)(AppComponent, { loading: loading, error: error, userLogin: userLogin, login: login, logout: logout, environment: environment, accessToken: accessToken }), accessTokenExpired && (0, jsx_runtime_1.jsxs)("os-dialog", { openDialog: true, width: 500, dialogTitle: trSessionExpiryTitle, dialogPosition: "top", className: "os-header-language-dialog", cancelBehavior: "none", onojClose: login, children: [trSessionExpiryDescription, (0, jsx_runtime_1.jsxs)("div", { slot: "dialog-footer", className: "oj-flex oj-sm-justify-content-flex-end", children: [(0, jsx_runtime_1.jsx)("oj-button", { id: "cancel", class: "oj-sm-margin-4x-end", onojAction: logout, children: trCancel }), (0, jsx_runtime_1.jsx)("oj-button", { id: "signIn", chroming: "callToAction", onojAction: login, children: trSignIn })] })] })] }) }) }));
    };
    exports.App = (0, ojvcomponent_1.registerCustomElement)("app-root", AppContainer, "App");
    (0, environment_1.debug)("VERSION", process.env.VERSION);
});
